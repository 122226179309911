let cat = RAC.coreDataLayer.productCategory;
let subcat = RAC.coreDataLayer.productSubCategory;
let { pageType, productBrand, productRmsId, preApprovalWeeklyAmountOffered } = RAC.coreDataLayer;
if (!isNullUndefined(subcat)) {
	cat = subcat;
}

function zync_call() {
	let z = document.createElement("script");
	let zmpID = $('#zetaGlobalConfigs').data('zeta-zmp-id');
	let categoryID = cat;
	let productID = productRmsId;
	let custom1 = pageType;
	let custom2 = productBrand;
	let custom4 = preApprovalWeeklyAmountOffered;
	let cache_buster = $('#zetaGlobalConfigs').data('zeta-cache-buster');
	let rental_pixel = $('#zetaGlobalConfigs').data('zeta-rezync-rental-pixel');
	let zeta_rezync_url = $('#zetaGlobalConfigs').data('zeta-rezync-url'); // Get zeta-rezync-url

	// Construct the z_src URL
	let z_src = isNullUndefined(zeta_rezync_url) ? '' : zeta_rezync_url;

	if (!isNullUndefined(rental_pixel)) {
		z_src += "&k=" + rental_pixel;
	}
	if (!isNullUndefined(zmpID)) {
		z_src += "&zmpID=" + zmpID;
	}
	if (!isNullUndefined(categoryID)) {
		z_src += "&categoryID=" + categoryID;
	}
	if (!isNullUndefined(productID)) {
		z_src += "&productID=" + productID;
	}
	if (!isNullUndefined(custom1)) {
		z_src += "&custom1=" + custom1;
	}
	if (!isNullUndefined(custom2)) {
		z_src += "&custom2=" + custom2;
	}
	if (!isNullUndefined(custom4)) {
		z_src += "&custom4=" + custom4;
	}
	if (!isNullUndefined(cache_buster)) {
		z_src += "&cache_buster=" + Date.now();
	}

	// Set the src attribute of the script element
	z.setAttribute("src", z_src);
	document.body.appendChild(z);
}

if (['complete', 'interactive'].indexOf(document.readyState) >= 0) {
	zync_call();
} else {
	window.addEventListener("DOMContentLoaded", function () {
		zync_call();
	});
}